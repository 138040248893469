import { useMemo } from 'react'
import { SlotInfo } from '@valerahealth/ui-components/features/Calendar'
import {
  SearchFilterEnum,
  schedulingApi,
  Operator,
  AppointmentParticipantTypeCode,
  ServiceCategoryCode,
  appointmentIntegrationsApi,
} from '@valerahealth/rtk-query'
import { Typography } from '@valerahealth/ui-components/base'
import { setHours, fromZonedTime } from '@valerahealth/ui-components/utils/date'
import { Calendar, ActionButtons } from '../components'
import ProviderCalendarContextProvider, {
  type ProviderCalendarContextProps,
} from '../components/ProviderCalendarContext'
import { useGetPermissions } from '../hooks'
import {
  actions,
  CalendarProviderType,
  nonCanceledStatus,
  useReduxDispatch,
  useReduxSelector,
} from '../reducer'
import { AvailabilityStatusWithNote } from '../components/ActionButtons'
import { getTimeWindowFromCalendarState } from '../utilities'

export type ProviderScheduleProps = { provider: CalendarProviderType }

const nonCanceledStatusSet = new Set(nonCanceledStatus)

const { useGetSchedulesByProviderIdQuery, useSearchAppointmentsQuery } =
  schedulingApi

const { usePullSyncMutation, usePushSyncMutation } = appointmentIntegrationsApi

export default function ProviderCalendar({ provider }: ProviderScheduleProps) {
  const dispatch = useReduxDispatch()

  const { calendarState, filterCanceledAppts } = useReduxSelector(
    (state) => state.scheduling,
  )
  const { view, timezone } = calendarState
  const { canReadAppointment, canReadSchedule, canCreateAppointment } =
    useGetPermissions(provider._id, 'providerId')

  const timeWindow: { start: Date; end: Date } | null = useMemo(() => {
    return getTimeWindowFromCalendarState(calendarState)
  }, [calendarState])
  const [, pullStatus] = usePullSyncMutation({
    fixedCacheKey: 'shared-pull',
  })
  const [, pushStatus] = usePushSyncMutation({
    fixedCacheKey: 'shared-push',
  })

  const { schedules, ...scheduleStatus } = useGetSchedulesByProviderIdQuery(
    {
      id: provider._id,
    },
    {
      skip: !canReadSchedule,
      selectFromResult: ({ data, isFetching, isError }) => ({
        isFetching,
        isError,
        schedules: data?.searchSchedules?.results || [],
      }),
    },
  )
  const { appointments: _appointments, ...appointmentStatus } =
    useSearchAppointmentsQuery(
      {
        content: [
          {
            filter: SearchFilterEnum.ProviderId,
            value: provider._id,
          },
          {
            filter: SearchFilterEnum.ParticipantTypeCode,
            value: AppointmentParticipantTypeCode.PrimaryPerformer,
          },
          {
            filter: SearchFilterEnum.EndDate,
            operator: Operator.Gte,
            value: timeWindow?.start.toISOString() || '',
          },
          {
            filter: SearchFilterEnum.StartDate,
            operator: Operator.Lte,
            value: timeWindow?.end.toISOString() || '',
          },
        ],
        pageSize: 10000,
      },
      {
        skip: !timeWindow || !canReadAppointment,
        selectFromResult: ({ isFetching, isError, data }) => ({
          isFetching,
          isError,
          appointments: data?.searchAppointments?.results,
        }),
      },
    )

  const appointments = useMemo(() => {
    if (!_appointments) return []
    const filteredAppts = filterCanceledAppts
      ? _appointments.filter((a) => nonCanceledStatusSet.has(a.status))
      : _appointments
    return [
      ...filteredAppts.filter((a) => nonCanceledStatusSet.has(a.status)),
      ...filteredAppts.filter((a) => !nonCanceledStatusSet.has(a.status)),
    ]
  }, [_appointments, filterCanceledAppts])

  if (scheduleStatus.isError || appointmentStatus.isError) {
    return <Typography color="error">Failed to load data</Typography>
  }

  const openAppointmentForm = (slotInfo: SlotInfo) => {
    const startDateTime = fromZonedTime(
      view === 'month' ? setHours(slotInfo.start, 8) : slotInfo.start,
      timezone,
    ).toISOString()

    dispatch(
      actions.openView({
        type: 'appointmentForm',
        mode: 'add',
        code: ServiceCategoryCode.Patient,
        initialState: {
          providerId: provider._id,
          startDateTime,
          timezone,
        },
      }),
    )
  }
  const isLoading =
    appointmentStatus.isFetching ||
    scheduleStatus.isFetching ||
    pullStatus.isLoading ||
    pushStatus.isLoading
  return (
    <ProviderCalendarContextProvider
      provider={provider}
      schedules={schedules}
      appointments={appointments}
      isLoading={isLoading}
    >
      <Calendar
        {...(process.env.SCHEDULING_ADD_EDIT_APPOINTMENTS &&
        canCreateAppointment
          ? {
              selectable: true,
              onSelectSlot: openAppointmentForm,
            }
          : null)}
      />
    </ProviderCalendarContextProvider>
  )
}
