import { useMemo, useState } from 'react'
import {
  appointmentIntegrationsApi,
  Availability,
  SERVICE_CATEGORIES,
  ServiceCategoryCode,
} from '@valerahealth/rtk-query'
import {
  Stack,
  IconButton,
  PopupMenu,
  ListItemText,
  ListItemButton,
  IconButtonLink,
  Divider,
  Paper,
  FormControl,
  InputLabel,
  Chip,
  Box,
  Tooltip,
  Modal,
  CircularProgress,
} from '@valerahealth/ui-components/base'
import {
  MoreHoriz,
  Add,
  DescriptionOutlined,
  Visibility,
  VisibilityOff,
  Sync,
} from '@valerahealth/ui-components/icons'
import { TFunction } from '@valerahealth/ui-translation'
import { useTranslation } from '../locales'
import { useProviderCalendarContext } from './ProviderCalendarContext'
import { useGetMutationPermissionsForPMCalendar } from '../hooks/useGetMutationPermissions'
import { actions, useReduxDispatch, useReduxSelector } from '../reducer'
import { getDrChronoAppointmentURL, getIdsFromProvider } from '../utilities'
import { SyncToEMR } from './AppointmentSync/SyncToEMR'
import { SyncHistory } from './AppointmentSync/SyncHistory'

const { useGetSyncHistoryQuery } = appointmentIntegrationsApi

const AvailabilityStatusChip = ({
  value,
  t,
}: {
  value: Availability
  t: TFunction
}) => {
  let color: 'success' | 'warning' | 'error' = 'success'

  if (
    [
      Availability.CareCoordinatorOnly,
      Availability.WaitlistOnly,
      Availability.PilotOnly,
    ].includes(value)
  )
    color = 'warning'

  if (
    [
      Availability.Pending,
      Availability.Recruiting,
      Availability.Closed,
    ].includes(value)
  )
    color = 'error'

  return (
    <Paper
      elevation={0}
      variant="outlined"
      sx={{
        minWidth: 100,
        position: 'relative',
      }}
    >
      <FormControl
        sx={{
          m: 1,
          minWidth: 100,
          position: 'absolute',
        }}
      >
        <InputLabel
          shrink
          sx={{
            position: 'absolute',
            backgroundColor: 'white',
            top: -9,
            left: -14,
            pl: 1,
            pr: 1,
          }}
        >
          {t('availability')}
        </InputLabel>
      </FormControl>
      <Chip
        sx={{
          m: 1,
        }}
        size="small"
        label={t(`Availability.${value}`)}
        color={color}
      />
    </Paper>
  )
}

export const AvailabilityStatusWithNote = () => {
  const {
    provider: { availability, availabilityNotes },
  } = useProviderCalendarContext()
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        minWidth: 300,
        display: 'flex',
        justifyItems: 'center',
      }}
    >
      {availability && <AvailabilityStatusChip value={availability} t={t} />}
      {availabilityNotes && (
        <Tooltip title={availabilityNotes} placement="right-end">
          <IconButton sx={{ ml: 0, pl: 0 }} disableRipple>
            <DescriptionOutlined />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}

export default function ActionButtons() {
  const { provider, appointments, schedules, isLoading } = useProviderCalendarContext()

  const dispatch = useReduxDispatch()
  const { filterCanceledAppts } = useReduxSelector((state) => state.scheduling)

  const [openModal, setOpenModal] = useState<
    'forceSync' | 'syncHistory' | null
  >(null)
  const { t } = useTranslation()
  const {
    canCreateSchedule,
    canReadAppointment,
    canCreateAppointment,
    canReadSchedule,
  } = useGetMutationPermissionsForPMCalendar(provider._id)
  const { syncHistory, ...syncStatus } = useGetSyncHistoryQuery(
    {
      providerId: provider._id,
    },
    {
      skip: !openModal,
      selectFromResult: ({ data, isLoading, isFetching, isError }) => ({
        syncHistory: data?.getSyncHistory || undefined,
        isLoading,
        isFetching,
        isError,
      }),
    },
  )
  const scheduleTypes = useMemo(
    () =>
      schedules
        ? SERVICE_CATEGORIES.filter((c) =>
            schedules.some((v) => v.serviceCategory.code === c.code),
          )
        : [],
    [schedules],
  )
  const { providerNextGenId } = getIdsFromProvider(provider)

  return (
    <Stack direction="row" justifyContent="flex-end" gap={2}>
      <IconButton
        title={
          filterCanceledAppts
            ? 'Show Canceled Appointments'
            : 'Hide Canceled Appointments'
        }
        sx={{
          color: (theme) => theme.palette.secondary.main,
          borderRadius: '64px',
        }}
        disableRipple
        onClick={() => {
          dispatch(actions.setfilterCanceledAppts(!filterCanceledAppts))
        }}
      >
        {filterCanceledAppts ? <VisibilityOff /> : <Visibility />}
      </IconButton>

      {providerNextGenId && (
        <>
          <PopupMenu
            variant="menu"
            button={
              syncStatus.isLoading || isLoading ? (
                <CircularProgress />
              ) : (
                <IconButton
                  sx={{
                    backgroundColor: (theme) => theme.palette.divider,
                    borderRadius: '64px',
                  }}
                  disableRipple
                >
                  <Sync />
                </IconButton>
              )
            }
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <ListItemButton onClick={() => setOpenModal('forceSync')}>
              <ListItemText>Sync Appointments</ListItemText>
            </ListItemButton>
            <ListItemButton onClick={() => setOpenModal('syncHistory')}>
              <ListItemText>Sync History</ListItemText>
            </ListItemButton>
          </PopupMenu>
          <Modal
            dialogProps={{
              fullWidth: true,
              maxWidth: 'lg',
            }}
            title="Sync to EMR"
            open={openModal === 'forceSync'}
            onClose={() => {
              setOpenModal(null)
            }}
          >
            <SyncToEMR
              appointments={appointments}
              syncHistory={syncHistory}
              isLoading={syncStatus.isLoading}
              onClose={() => {
                setOpenModal(null)
              }}
            />
          </Modal>
          <Modal
            dialogProps={{
              fullWidth: true,
              maxWidth: 'lg',
            }}
            title="Sync History"
            open={openModal === 'syncHistory'}
            onClose={() => {
              setOpenModal(null)
            }}
          >
            <SyncHistory
              syncHistory={syncHistory}
              isLoading={syncStatus.isLoading}
            />
          </Modal>
        </>
      )}

      {process.env.SCHEDULING_ADD_EDIT_APPOINTMENTS ? (
        canCreateAppointment && (
          <IconButton
            sx={{
              backgroundColor: (theme) => theme.palette.divider,
              borderRadius: '64px',
            }}
            onClick={() => {
              dispatch(
                actions.openView({
                  type: 'appointmentForm',
                  mode: 'add',
                  code: ServiceCategoryCode.Patient,
                  initialState: {
                    providerId: provider._id,
                  },
                }),
              )
            }}
          >
            <Add />
          </IconButton>
        )
      ) : (
        <IconButtonLink
          to={getDrChronoAppointmentURL()}
          sx={{
            backgroundColor: (theme) => theme.palette.divider,
            borderRadius: '64px',
          }}
        >
          <Add />
        </IconButtonLink>
      )}
      {((scheduleTypes.length && canReadSchedule) ||
        canCreateSchedule ||
        canReadAppointment) && (
        <PopupMenu
          variant="menu"
          button={
            <IconButton
              sx={{
                backgroundColor: (theme) => theme.palette.divider,
                borderRadius: '64px',
              }}
              disableRipple
            >
              <MoreHoriz />
            </IconButton>
          }
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {canCreateSchedule && (
            <ListItemButton
              onClick={() => {
                dispatch(
                  actions.openView({
                    type: 'scheduleForm',
                    mode: 'add',
                    provider,
                  }),
                )
              }}
            >
              <ListItemText
                sx={{
                  mr: 'auto',
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {t('Add Schedule')}
              </ListItemText>
              <Add sx={{ color: (theme) => theme.palette.action.active }} />
            </ListItemButton>
          )}
          {scheduleTypes.map(({ code }) => (
            <ListItemButton
              key={code}
              onClick={() =>
                dispatch(
                  actions.openView({
                    type: 'scheduleList',
                    code,
                    provider,
                  }),
                )
              }
            >
              <ListItemText>{t(`ServiceCategoryCode.${code}`)}</ListItemText>
            </ListItemButton>
          ))}
          <Divider />
          <ListItemButton
            onClick={() =>
              dispatch(
                actions.openView({
                  type: 'appointmentList',
                  code: ServiceCategoryCode.OutOfOffice,
                  mode: 'future',
                  providerId: provider._id,
                }),
              )
            }
          >
            <ListItemText>
              Upcoming{' '}
              {t(`ServiceCategoryCode.${ServiceCategoryCode.OutOfOffice}`)}
            </ListItemText>
          </ListItemButton>
        </PopupMenu>
      )}
    </Stack>
  )
}
