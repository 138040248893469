import {
  ToolbarProps,
  Topbar,
} from '@valerahealth/ui-components/features/Calendar'
import { useMemo } from 'react'
import { TIMEZONE_VALUES } from '@valerahealth/ui-components/utils/date'
import { CalendarEventType } from './Calendar.type'
import { useProviderCalendarContext } from '../ProviderCalendarContext'
import ActionButtons, { AvailabilityStatusWithNote } from '../ActionButtons'

interface CalendarToolbarProps extends ToolbarProps<CalendarEventType> {}

export function CalendarToolbar() {
  const { provider, isLoading } = useProviderCalendarContext()
  const nextgenTimezone = useMemo(() => {
    return (
      TIMEZONE_VALUES.find(({ value }) => value === provider.nextgenTimezone)
        ?.label || ''
    )
  }, [provider])
  return (
    <Topbar
      actionButtons={<ActionButtons />}
      otherComponents={<AvailabilityStatusWithNote />}
      nextgenTimezone={nextgenTimezone}
      isLoading={isLoading}
    />
  )
}
