import {
  Permission,
  checkPermission,
  checkOwnPermissions,
  useReduxSelectorWithAuthState,
} from '@valerahealth/redux-auth'

export function useDeterminePermissions(
  permission: Permission,
  ownPermission: Permission,
  id: string,
  idType: 'providerId' | 'careManagerId',
) {
  const allowed = useReduxSelectorWithAuthState((state) =>
    checkPermission(state, permission),
  )
  const [allowedOwn] = useReduxSelectorWithAuthState((state) =>
    checkOwnPermissions(state, [ownPermission], id!, idType),
  )
  if (allowed) return true
  return allowedOwn
}

export function useGetPermissions(
  id: string,
  idType: 'providerId' | 'careManagerId',
) {
  const canReadAppointment = useDeterminePermissions(
    Permission.Appointment_Read,
    Permission.Appointment_ReadOwn,
    id,
    idType,
  )
  const canCreateAppointment = useDeterminePermissions(
    Permission.Appointment_Create,
    Permission.Appointment_CreateOwn,
    id,
    idType,
  )
  const canDeleteAppointment = useDeterminePermissions(
    Permission.Appointment_Delete,
    Permission.Appointment_DeleteOwn,
    id,
    idType,
  )
  const canUpdateAppointment = useDeterminePermissions(
    Permission.Appointment_Update,
    Permission.Appointment_UpdateOwn,
    id,
    idType,
  )
  const canReadSchedule = useDeterminePermissions(
    Permission.Schedule_Read,
    Permission.Schedule_ReadOwn,
    id,
    idType,
  )
  const canCreateSchedule = useDeterminePermissions(
    Permission.Schedule_Create,
    Permission.Schedule_CreateOwn,
    id,
    idType,
  )
  const canDeleteSchedule = useDeterminePermissions(
    Permission.Schedule_Delete,
    Permission.Schedule_DeleteOwn,
    id,
    idType,
  )
  const canUpdateSchedule = useDeterminePermissions(
    Permission.Schedule_Update,
    Permission.Schedule_UpdateOwn,
    id,
    idType,
  )

  return {
    canReadAppointment,
    canCreateAppointment,
    canDeleteAppointment,
    canUpdateAppointment,
    canReadSchedule,
    canCreateSchedule,
    canDeleteSchedule,
    canUpdateSchedule,
  }
}

// use this when current url links to a PM calendar page
export function useGetMutationPermissionsForPMCalendar(providerId: string) {
  return useGetPermissions(providerId, 'providerId')
}
