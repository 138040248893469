import React, { useCallback, useMemo } from 'react'
import { type View } from 'react-big-calendar'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import MenuItem from '@mui/material/MenuItem'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { StaticDatePicker } from '@mui/x-date-pickers-pro'
import { Box } from '@mui/system'
import {
  Divider,
  LinearProgress,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material'
import { addMonths, addWeeks, addDays, startOfISOWeek, Locale } from 'date-fns'
import {
  toZonedTime,
  format as defaultFormat,
  fromZonedTime,
  FormatOptionsWithTZ,
} from 'date-fns-tz'
import { enUS } from 'date-fns/locale/en-US'
import {
  TableRowsSharp,
  ViewHeadlineSharp,
  ViewStreamSharp,
} from '@mui/icons-material'
import { SingleSelect } from '../../base/Select/index'
import { getTimezoneValues } from '../../utils/date'
import { useTranslation } from '../../utils/hooks'
import { useCalendarContext } from './CalendarContext'
import { PopupMenu } from '../../base'
import { CalendarDensity } from './Calendar.type'

interface Props {
  actionButtons?: React.ReactNode
  otherComponents?: React.ReactNode
  isLoading?: boolean
  nextgenTimezone?: string | null | undefined
}

const defaultOption: FormatOptionsWithTZ = {
  // default to US English
  locale: enUS,
}

const CalendarDensityControl = () => {
  const { t } = useTranslation()
  const { selectCalendarState, actions: calendarOptions } = useCalendarContext()
  const { calendarDensity = 'COMPACT', view } = selectCalendarState()

  const calendarDensities = useMemo(
    () => [
      {
        density: 'COMPACT',
        icon: (
          <ViewHeadlineSharp
            sx={{ color: (theme) => theme.palette.text.primary }}
          />
        ),
        selected: calendarDensity === 'COMPACT',
      },
      {
        density: 'STANDARD',
        icon: (
          <ViewStreamSharp
            sx={{ color: (theme) => theme.palette.text.primary }}
          />
        ),
        selected: calendarDensity === 'STANDARD',
      },
      {
        density: 'COMFORTABLE',
        icon: (
          <TableRowsSharp
            sx={{ color: (theme) => theme.palette.text.primary }}
          />
        ),
        selected: calendarDensity === 'COMFORTABLE',
      },
    ],
    [calendarDensity],
  )

  const selectedDensityIcon = useMemo(
    () => calendarDensities.find((x) => x.selected)?.icon,
    [calendarDensities],
  )

  return view === 'week' || view === 'day' ? (
    <PopupMenu
      variant="menu"
      button={
        <Tooltip title="Density">
          <IconButton disableRipple>{selectedDensityIcon}</IconButton>
        </Tooltip>
      }
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {calendarDensities.map(({ density, icon, selected }) => (
        <ListItemButton
          onClick={() => {
            calendarOptions.changeCalendarDensity(density as CalendarDensity)
            localStorage.setItem('calendarDensity', density)
          }}
          selected={selected}
        >
          {icon}
          <ListItemText>{t(`CalendarDensityOption.${density}`)}</ListItemText>
        </ListItemButton>
      ))}
    </PopupMenu>
  ) : null
}

export function Topbar({
  actionButtons = null,
  otherComponents = null,
  isLoading,
  nextgenTimezone,
}: Props) {
  const { selectCalendarState, actions } = useCalendarContext()
  const state = selectCalendarState()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const timezoneOptions = getTimezoneValues()
  const { t } = useTranslation()

  const format = useCallback(
    (date: Date, formatString: string) =>
      defaultFormat(date, formatString, defaultOption),
    [],
  )

  const calendarZonedTime = useMemo(
    () => toZonedTime(state.calenderDate, state.timezone),
    [state.calenderDate, state.timezone],
  )

  const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleDatePick = (timezone: string) => (date: Date | null) => {
    if (date) {
      actions.changeCalendarDate(fromZonedTime(date, timezone).toISOString())
    }
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Stack direction="row" alignItems="flex-start" spacing={1}>
          <Button
            variant="outlined"
            onClick={() =>
              actions.changeCalendarDate(
                toZonedTime(new Date(), state.timezone).toISOString(),
              )
            }
          >
            {t(`today`)}
          </Button>
          <Stack direction="row" alignItems="center">
            <IconButton
              disableRipple
              onClick={() =>
                actions.changeCalendarDate(
                  state.view === 'month'
                    ? addMonths(new Date(state.calenderDate), -1).toISOString()
                    : state.view === 'week'
                    ? addWeeks(new Date(state.calenderDate), -1).toISOString()
                    : state.view === 'day'
                    ? addDays(new Date(state.calenderDate), -1).toISOString()
                    : state.calenderDate,
                )
              }
            >
              <ArrowBackIosNewIcon />
            </IconButton>
            <Typography onClick={handlePopoverClick}>
              {state.view === 'month'
                ? format(calendarZonedTime, 'MMM yyyy')
                : state.view === 'week'
                ? `${format(startOfISOWeek(calendarZonedTime), 'MMM yyyy')}`
                : state.view === 'day'
                ? format(calendarZonedTime, 'PP')
                : state.calenderDate}
            </Typography>
            <Popover
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              id={anchorEl ? 'simple-popover' : undefined}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
            >
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                openTo="day"
                views={['year', 'month', 'day']}
                minDate={new Date('2000-01-01T00:00:00.000')}
                maxDate={new Date('2040-01-01T00:00:00.000')}
                value={new Date(state.calenderDate)}
                onChange={handleDatePick(state.timezone)}
              />
            </Popover>
            <IconButton
              disableRipple
              onClick={() =>
                actions.changeCalendarDate(
                  state.view === 'month'
                    ? addMonths(new Date(state.calenderDate), 1).toISOString()
                    : state.view === 'week'
                    ? addWeeks(new Date(state.calenderDate), 1).toISOString()
                    : state.view === 'day'
                    ? addDays(new Date(state.calenderDate), 1).toISOString()
                    : state.calenderDate,
                )
              }
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Stack>
          <Divider
            orientation="vertical"
            sx={{ height: nextgenTimezone ? '75%' : '100%' }}
          />
          <SingleSelect<string>
            variant="outlined"
            formControlProps={{ sx: { m: 1, minWidth: 120 }, size: 'small' }}
            label={t(`timezone`)}
            onChange={actions.changeTimezone}
            value={state.timezone}
            helperText={
              nextgenTimezone ? t('nextgenTimezoneIs', { nextgenTimezone }) : ''
            }
          >
            {timezoneOptions.map((tz) => (
              <MenuItem key={tz.value} value={tz.value}>
                {t(tz.label)}
              </MenuItem>
            ))}
          </SingleSelect>
          <SingleSelect<View>
            variant="outlined"
            formControlProps={{
              sx: { m: 1, minWidth: 100 },
              size: 'small',
            }}
            label={t(`View`)}
            onChange={actions.changeView}
            value={state.view}
          >
            <MenuItem value="month">{t(`Month`)}</MenuItem>
            <MenuItem value="week">{t(`Week`)}</MenuItem>
            <MenuItem value="day">{t(`Day`)}</MenuItem>
          </SingleSelect>
          {otherComponents}
        </Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
          }}
        >
          <CalendarDensityControl />
          {actionButtons}
        </Box>
      </Box>

      <Box
        sx={{
          height: '1rem',
          alignItems: 'stretch',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {isLoading && <LinearProgress />}
      </Box>
    </>
  )
}
