import { BaseApiType } from './baseApi'

export default function enhanceEndpoints(api: BaseApiType) {
  return api.enhanceEndpoints({
    endpoints: {
      checkEmrAvailability: {
        keepUnusedDataFor: 0,
        providesTags: (res, err, req) => {
          return [
            { type: 'availability' as const, id: req!.content?.providerEmrId },
          ]
        },
      },
      getSyncHistory: {
        keepUnusedDataFor: 300,
        providesTags: (res, err, req) => {
          return (
            res?.getSyncHistory?.map((h) => ({
              type: 'syncHistory',
              id: h.appointmentId!,
            })) || ['syncHistory']
          )
        },
      },
      pullSync: {
        invalidatesTags: ['syncHistory'],
      },
      pushSync: {
        invalidatesTags: ['syncHistory'],
      },
    },
  })
}
