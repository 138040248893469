import { Box, Divider, Typography } from '@mui/material'
import { SyncHistory as SyncHistoryType } from '@valerahealth/rtk-query'
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  renderHoverFullContentCell,
} from '@valerahealth/ui-components/grid'
import { useMemo } from 'react'
import { formatTz } from '@valerahealth/ui-components/utils/date'
import { Cancel, Check } from '@mui/icons-material'
import capitalize from 'lodash/capitalize'
import { useReduxSelector } from '../../reducer'
import { getIdsFromProvider } from '../../utilities'
import { useProvider } from '../ProviderCalendarContext'

type RowDataType = {
  id: string
  appointmentId: string
  _meta: SyncHistoryType
  date: Date | null
  direction: string
  apptDate: Date | null
  message: string
  status: string
}

function IdChip({ label, id }: { label: string; id?: string }) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        m: '2px 10px 2px 6px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {!id ? (
        <>
          <Cancel color="error" />
          <Typography color="error">{label}</Typography>
        </>
      ) : (
        <>
          <Check color="success" />
          <Typography>{label}</Typography>
        </>
      )}
    </Box>
  )
}

export function SyncHistory({
  syncHistory: _syncHistory,
  filterByVHApptId,
  isLoading,
}: {
  syncHistory?: SyncHistoryType[] | null
  filterByVHApptId?: string[]
  isLoading?: boolean
}) {
  const provider = useProvider()
  const { timezone } = useReduxSelector(
    (state) => state.scheduling.calendarState,
  )
  const { providerNextGenId, lumaId, providerNextGenResourceId, npi } =
    getIdsFromProvider(provider)
  const syncHistory =
    filterByVHApptId && filterByVHApptId.length
      ? _syncHistory?.filter((h) =>
          filterByVHApptId.includes(h.appointmentId || ''),
        )
      : _syncHistory
  const columns: GridColDef<RowDataType>[] = useMemo(
    () => [
      {
        field: 'date',
        type: 'date',
        headerName: 'Sync Date',
        minWidth: 220,
        valueFormatter: (params: GridValueFormatterParams<Date>) =>
          formatTz(params.value, 'Pp zzz', {
            timeZone: timezone,
          }),
      },
      {
        field: 'direction',
        type: 'singleSelect',
        headerName: 'Direction',
        valueOptions: [
          { value: 'inbound', label: 'Inbound' },
          { value: 'outbound', label: 'Outbound' },
        ],
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          capitalize(params.value),
        minWidth: 100,
      },
      {
        field: 'appointmentId',
        headerName: 'Appt ID',
        minWidth: 220,
        type: 'string',
      },
      {
        field: 'apptDate',
        headerName: 'Appt Date',
        type: 'date',
        minWidth: 220,
        valueFormatter: (params: GridValueFormatterParams<Date>) =>
          formatTz(params.value, 'Pp zzz', {
            timeZone: timezone,
          }),
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'singleSelect',
        valueOptions: [
          { value: 'error', label: 'Error' },
          { value: 'success', label: 'Success' },
          // { value: 'skip', label: 'Skip' },
        ],
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          capitalize(params.value),
        minWidth: 100,
      },
      {
        field: 'message',
        headerName: 'Message',
        minWidth: 150,
        type: 'string',
        renderCell: renderHoverFullContentCell,
      },
    ],
    [timezone],
  )
  const rows: RowDataType[] = useMemo(() => {
    return (
      syncHistory
        ?.filter((h) => h.id)
        .map((h) => ({
          _meta: h,
          id: h.id!,
          appointmentId: h.appointmentId || '',
          date: new Date(h.dateTime),
          direction: h.direction,
          apptDate: h.appointmentDate ? new Date(h.appointmentDate) : null,
          status: h.status || '',
          message: h.message || '',
        })) || []
    )
  }, [syncHistory])
  return (
    <Box sx={{ minWidth: '600px', height: '100%' }}>
      <Box display="flex" flexDirection="row" sx={{ mb: '10px' }}>
        <IdChip id={providerNextGenId} label="NextGen ID" />
        <IdChip id={providerNextGenResourceId} label="NextGen Resource ID" />
        <IdChip id={lumaId} label="Luma ID" />
        <IdChip id={npi} label="NPI" />
      </Box>
      <Divider sx={{ mb: '20px' }} />
      <DataGrid
        autoHeight
        loading={isLoading}
        columns={columns}
        rows={rows}
        pagination
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        disableRowSelectionOnClick
        autosizeOptions={{
          columns: ['id', 'date', 'direction', 'apptDate', 'status'],
          includeOutliers: true,
          includeHeaders: false,
        }}
        slotProps={{
          toolbar: {
            disableFeatures: ['quickFilter', 'densitySelector', 'exportButton'],
          },
        }}
      />
    </Box>
  )
}
