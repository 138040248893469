import {
  CSSProperties,
  Fragment,
  useCallback,
  useMemo,
  useState,
} from 'react'
import BaseCalendar, {
  DateLocalizer,
  overlap,
  SlotInfo,
} from '@valerahealth/ui-components/features/Calendar'
import {
  formatInTimeZone,
  getHours,
  getMonth,
  getYear,
} from '@valerahealth/ui-components/utils/date'
import {
  ServiceCategoryCode,
  ServiceTypeCode,
  AppointmentStatus,
} from '@valerahealth/rtk-query'
import {
  Typography,
  Popover,
  PopupState,
  bindTrigger,
  bindPopover,
  Box,
  Paper,
  Divider,
  theme,
} from '@valerahealth/ui-components'
import { appointmentToEvent, scheduleToEvent } from '../../utilities/utilities'

import { useTranslation } from '../../locales'
import { Event } from './EventWeek'
import { EventMonth } from './EventMonth'
import { AppointmentEventType, CalendarEventType } from './Calendar.type'
import {
  BACKGROUND_EVENT_BORDER_PX,
  CANCELED_APPOINTMENT_STATUSES,
  CARD_GUTTER_WIDTH_REMS,
} from '../../utilities/constants'
import { useReduxDispatch, useReduxSelector } from '../../reducer'
import { CalendarToolbar } from './CalendarToolbar'
import { useProviderCalendarContext } from '../ProviderCalendarContext'

export interface CalendarProps {
  selectable?: boolean
  onSelectSlot?: (slotInfo: SlotInfo) => void
}

function timeGutterFormat(
  date: Date,
  culture?: string,
  localizer?: DateLocalizer,
) {
  return localizer
    ? getHours(date) === 0
      ? ''
      : localizer.format(date, 'h a', culture)
    : ''
}

const useSelectCalendarState = () => {
  return useReduxSelector((state) => state.scheduling.calendarState)
}

export function Calendar({ selectable, onSelectSlot }: CalendarProps) {
  const state = useReduxSelector((state) => state.scheduling.calendarState)
  const { appointments, schedules } = useProviderCalendarContext()
  const [year, month] = useMemo(() => {
    const date = new Date(state.calenderDate)
    return [getYear(date), getMonth(date)]
  }, [state.calenderDate])
  const { t } = useTranslation()
  const scheduleEvents = useMemo(
    () => schedules.flatMap((s) => scheduleToEvent(year, month, s, t)),
    [schedules, year, month, t],
  )

  const appointmentEvents = useMemo(() => {
    const apptsEvents = appointments
      .slice()
      .map((a) => appointmentToEvent(a, t))
    if (state.view !== 'month') return apptsEvents
    return apptsEvents.filter(
      (e) =>
        e.resource.type === 'appointment' &&
        !(
          e.resource.serviceType === ServiceTypeCode.Brk &&
          e.resource.serviceCategory === ServiceCategoryCode.Break
        ) &&
        e.resource.serviceType !== ServiceTypeCode.Adm,
    )
  }, [appointments, state.view, t])

  // for monthly view

  const [popupParams, setPopupParams] = useState<{
    events: AppointmentEventType[]
    date: Date
  } | null>(null)

  const showMore = useCallback(
    (total: number) => (
      <PopupState variant="popover" popupId="monthly-showmore-popup">
        {(popupState) => {
          const countCompleted = (popupParams?.events || []).filter(
            (e) =>
              (e as AppointmentEventType).resource.status ===
              AppointmentStatus.Fulfilled,
          ).length
          const countScheduled = (popupParams?.events || []).filter(
            (e) =>
              (e as AppointmentEventType).resource.status ===
                AppointmentStatus.Booked ||
              (e as AppointmentEventType).resource.status ===
                AppointmentStatus.Noshow,
          ).length
          const title = popupParams
            ? `${formatInTimeZone(
                popupParams.date,
                state.timezone,
                'MMM d',
              )} (${countCompleted} completed / ${countScheduled} scheduled)`
            : ''
          return (
            <div>
              <Typography
                sx={{
                  ml: '4px',
                  fontSize: (theme) => theme.typography.caption,
                }}
                {...bindTrigger(popupState)}
              >
                <b>{`${total} more`}</b>
              </Typography>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 50,
                  horizontal: 50,
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
              >
                <Paper
                  sx={{
                    minWidth: '300px',
                    minHeight: '100px',
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ p: '4px 4px 4px 4px' }}
                  >
                    <Typography sx={{ ml: '2px' }}>{title}</Typography>
                    <Divider sx={{ mb: '2px' }} />
                    {(popupParams?.events || []).map((e) => (
                      <Fragment
                        key={
                          (e as AppointmentEventType).resource._appointment._id
                        }
                      >
                        <EventMonth event={e} />
                      </Fragment>
                    ))}
                  </Box>
                </Paper>
              </Popover>
            </div>
          )
        }}
      </PopupState>
    ),
    [popupParams, state.timezone],
  )

  const handleShowMore = useCallback(
    (events: CalendarEventType[], date: Date) => {
      const countedAppts = (events || []).filter(
        (e) =>
          e.resource.type === 'appointment' &&
          e.resource.serviceType !== ServiceTypeCode.Adm &&
          e.resource.serviceType !== ServiceTypeCode.Brk,
      ) as AppointmentEventType[]
      setPopupParams({ events: countedAppts, date })
    },
    [],
  )

  return (
    <BaseCalendar
      useDispatch={useReduxDispatch}
      selectCalendarState={useSelectCalendarState}
      handleDragStart={() => {}} // required for monthly view popup but nothing gets done for dragging events
      events={appointmentEvents as CalendarEventType[]}
      backgroundEvents={state.view === 'month' ? [] : scheduleEvents}
      components={{
        month: {
          event: EventMonth,
        },
        week: {
          event: Event,
        },
        day: {
          event: Event,
        },
        toolbar: CalendarToolbar,
      }}
      formats={{
        timeGutterFormat,
      }}
      eventPropGetter={(event) => {
        const { resource } = event
        if (state.view !== 'month' && resource?.type === 'appointment') {
          const { isCanceled, status } = resource
          const fontBorderColor = isCanceled
            ? resource.palette.topColor
            : resource.palette.topContrastText
          const background = isCanceled
            ? 'rgba(255,255,255,0.5)'
            : status === AppointmentStatus.Proposed
            ? `repeating-linear-gradient(-45deg, ${
                resource.palette.topColor
              }, ${resource.palette.topColor} 8px, ${theme.palette.withAlpha(
                resource.palette.topColor!,
                0.6,
              )} 2px, ${theme.palette.withAlpha(
                resource.palette.topColor!,
                0.6,
              )} 10px)`
            : resource.palette.topColor
          return {
            style: {
              border: `1px solid ${fontBorderColor}`,
              color: fontBorderColor,
              background,
              boxShadow: isCanceled ? 'none' : undefined,
            },
          }
        }
        return {}
      }}
      sx={{
        '.rbc-day-slot .rbc-events-container': {
          border: 0,
          margin: 0,
          // the 1px here is the border between days
          marginLeft: `calc(${CARD_GUTTER_WIDTH_REMS}rem + 1px)`,
          marginRight: `${BACKGROUND_EVENT_BORDER_PX}px`,
          pointerEvents: 'none',
        },
        '.rbc-event': {
          backgroundColor: 'transparent',
          padding: 0,
          borderRadius: '4px',
          margin: 0,
          pointerEvents: 'all',
          '&:focus': {
            outline: 0,
            outlineColor: 'transparent',
          },
        },
        '.rbc-day-slot div.rbc-background-event': {
          // 2px here is the background event border
          width: `calc(100% + ${CARD_GUTTER_WIDTH_REMS}rem + ${BACKGROUND_EVENT_BORDER_PX}px)`,
          // the 1 px here is the border in between days
          marginLeft: `calc(-${CARD_GUTTER_WIDTH_REMS}rem - 1px)`,
          overflow: 'visible',
        },
      }}
      dayLayoutAlgorithm={({ events, ...rest }) => {
        const result = overlap({
          ...rest,
          events: events.slice().sort((a, b) => {
            if (
              a.resource?.type === 'appointment' &&
              b.resource?.type === 'appointment'
            ) {
              const aCanc = CANCELED_APPOINTMENT_STATUSES.includes(
                a.resource.status,
              )
              const bCanc = CANCELED_APPOINTMENT_STATUSES.includes(
                b.resource.status,
              )
              return aCanc === bCanc ? 0 : aCanc ? -1 : 1
            }
            return -1
          }),
          minimumStartDifference: 15,
        }).map(
          ({
            event,
            style,
            ...rest
          }: {
            event: AppointmentEventType
            style: CSSProperties
          }) => ({
            ...rest,
            event,
            style: {
              ...style,
              height:
                event.resource?.type === 'appointment'
                  ? // the 1 px included in this is for the row border between hours
                    `calc(${style.height}% - ${
                      BACKGROUND_EVENT_BORDER_PX * 2 + 1
                    }px)`
                  : `calc(${style.height}% - 1px)`,
              top:
                event.resource?.type === 'appointment'
                  ? `calc(${style.top}% + ${BACKGROUND_EVENT_BORDER_PX}px)`
                  : style.top,
            },
          }),
        )
        return result
      }}
      popup={false}
      doShowMoreDrillDown={false}
      // @ts-ignore
      messages={{ showMore }}
      onShowMore={handleShowMore}
      selectable={selectable}
      onSelectSlot={onSelectSlot}
    />
  )
}

export default Calendar
