import {
  ServiceCategoryCode,
  SearchFilterEnum,
  schedulingApi,
  Operator,
  AppointmentParticipantTypeCode,
  SearchFilter,
  type AppointmentFragment,
  AppointmentIdentifierSource,
} from '@valerahealth/rtk-query'
import { startOfDay } from '@valerahealth/ui-components/utils/date'
const { useSearchAppointmentsQuery } = schedulingApi

const defaultContent: SearchFilter[] = [
  {
    filter: SearchFilterEnum.EndDate,
    value: startOfDay(new Date()).toISOString(),
    operator: Operator.Gte,
  },
]

const emptyResults: AppointmentFragment[] = []
export function useSearchOOOAppts(
  providerId: string,
  skip?: boolean,
  content = defaultContent,
) {
  const { data, ...result } = useSearchAppointmentsQuery(
    {
      content: [
        {
          filter: SearchFilterEnum.ProviderId,
          value: providerId,
        },
        {
          filter: SearchFilterEnum.ParticipantTypeCode,
          value: AppointmentParticipantTypeCode.PrimaryPerformer,
        },
        {
          filter: SearchFilterEnum.CategoryCode,
          value: ServiceCategoryCode.OutOfOffice,
        },
        ...(content || []),
      ],
    },
    { skip },
  )
  return {
    ...result,
    oooAppts: data?.searchAppointments?.results || emptyResults,
  }
}

export function useSearchApptsByProviderId(
  providerId: string,
  skip?: boolean,
  content = defaultContent,
) {
  const { data, ...result } = useSearchAppointmentsQuery(
    {
      content: [
        { filter: SearchFilterEnum.ProviderId, value: providerId },
        {
          filter: SearchFilterEnum.ParticipantTypeCode,
          value: AppointmentParticipantTypeCode.PrimaryPerformer,
        },
        ...content,
      ],
    },
    { skip },
  )
  return {
    ...result,
    ownAppts: data?.searchAppointments?.results || emptyResults,
  }
}

