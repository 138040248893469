import { ExpandMore } from '@mui/icons-material'
import {
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
} from '@mui/material'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import {
  AppointmentFragment,
  AppointmentIdentifierSource,
  appointmentIntegrationsApi,
  SyncHistory as SyncHistoryType,
  SyncStatus,
} from '@valerahealth/rtk-query'
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  GridToolbar,
  GridValueFormatterParams,
  renderHoverFullContentCell,
} from '@valerahealth/ui-components/grid'
import {
  addDays,
  formatTz,
  isBefore,
} from '@valerahealth/ui-components/utils/date'
import { DateRange, DateRangePicker } from '@valerahealth/ui-components/base'
import { useNotify } from '@valerahealth/ui-components'
import capitalize from 'lodash/capitalize'
import { NotifyFn } from '@valerahealth/ui-components/features/Notifications/reduxSlice'
import { useReduxSelector } from '../../reducer'
import { useProvider } from '../ProviderCalendarContext'

const { usePullSyncMutation, usePushSyncMutation } = appointmentIntegrationsApi

type RowDataType = {
  id: string
  _meta: AppointmentFragment
  date: Date
  direction: string
  apptDate: Date
  status: string
  message: string
}

function notifyRes(
  results:
    | Array<{
        appointmentId?: string | null
        status?: SyncStatus | null
      }>
    | null
    | undefined,
  notify: NotifyFn,
) {
  const count = results?.length
  if (!count) {
    notify({
      message: 'No appointment need to be synced',
      severity: 'success',
    })
  } else {
    const errorCount =
      results?.filter((r) => r.status === SyncStatus.Error).length || 0
    if (errorCount === 0) {
      notify({
        message: `All Appointments are synced.`,
        severity: 'success',
      })
    } else if (errorCount === count) {
      notify({
        message: `0/${count} Appointment is successfully synced.`,
        severity: 'error',
      })
    } else {
      notify({
        message: `${count - errorCount}/${count} appointments are synced.`,
        severity: 'warning',
      })
    }
  }
}

function getSyncHistoryByApptId(
  apptId: string,
  syncHistory?: SyncHistoryType[] | null,
) {
  if (!syncHistory || !syncHistory.length) return undefined
  const arr = syncHistory
    .filter((h) => h.appointmentId === apptId && h.appointmentDate)
    .sort((a, b) => (isBefore(a.appointmentDate!, b.appointmentDate!) ? 1 : -1))
  if (arr.length) return arr[0]
  return undefined
}

export function SyncToEMRSingle({
  appointment,
  syncHistory,
  isLoading,
  onClose,
}: {
  appointment: AppointmentFragment
  syncHistory?: SyncHistoryType[] | null
  isLoading?: boolean
  onClose: () => void
}) {
  const notify = useNotify()
  const [push, pushRes] = usePushSyncMutation({
    fixedCacheKey: 'shared-push',
  })
  const { timezone } = useReduxSelector(
    (state) => state.scheduling.calendarState,
  )

  const columns: GridColDef<RowDataType>[] = useMemo(
    () => [
      {
        field: 'date',
        type: 'date',
        headerName: 'Creation Date',
        minWidth: 220,
        valueFormatter: (params: GridValueFormatterParams<Date>) =>
          formatTz(params.value, 'Pp zzz', {
            timeZone: timezone,
          }),
      },
      {
        field: 'direction',
        type: 'singleSelect',
        headerName: 'Direction',
        valueOptions: [
          { value: 'inbound', label: 'Inbound' },
          { value: 'outbound', label: 'Outbound' },
        ],
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          capitalize(params.value),
        minWidth: 100,
      },
      {
        field: 'id',
        headerName: 'Appt ID',
        minWidth: 220,
        type: 'string',
      },
      {
        field: 'apptDate',
        headerName: 'Appt Date',
        type: 'date',
        minWidth: 220,
        valueFormatter: (params: GridValueFormatterParams<Date>) =>
          formatTz(params.value, 'Pp zzz', {
            timeZone: timezone,
          }),
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'singleSelect',
        valueOptions: [
          { value: 'error', label: 'Error' },
          { value: 'success', label: 'Success' },
          // { value: 'skip', label: 'Skip' },
        ],
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          capitalize(params.value),
        minWidth: 100,
      },
      {
        field: 'message',
        headerName: 'Message',
        minWidth: 150,
        type: 'string',
        renderCell: renderHoverFullContentCell,
      },
    ],
    [timezone],
  )

  const rows = useMemo(() => {
    const hist = getSyncHistoryByApptId(appointment._id, syncHistory)
    return [
      {
        _meta: appointment,
        id: appointment._id,
        date: new Date(appointment.createdDate),
        direction: 'Outbound',
        apptDate: new Date(appointment.startDate),
        status: hist?.status || '',
        message: hist?.message || '',
      },
    ]
  }, [appointment, syncHistory])

  const onSubmit = async () => {
    const res = await push({ appointmentIds: [appointment._id] })
    if (
      'error' in res ||
      res.data?.pushSync?.results?.some((r) => r.status === 'error')
    ) {
      notify({
        message: 'Sync Failure',
        severity: 'error',
      })
    } else {
      notifyRes(res.data.pushSync?.results, notify)
    }
  }
  return (
    <Box sx={{ minWidth: '600px', height: '100%' }}>
      <Typography component="span">
        <strong>Push</strong>
      </Typography>
      <Typography sx={{ mb: '8px' }}>
        Send this unsynced Valera appointment to NextGen:
      </Typography>
      <DataGrid
        autoHeight
        loading={isLoading}
        columns={columns}
        rows={rows}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        autosizeOptions={{
          columns: ['id', 'date', 'direction', 'apptDate'],
          includeOutliers: true,
          includeHeaders: false,
        }}
        disableColumnMenu
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          mt: '16px',
        }}
      >
        <Button
          variant="outlined"
          color="info"
          sx={{ ml: 'auto', mr: '16px' }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button onClick={onSubmit} disabled={pushRes.isLoading}>
          Sync
        </Button>
      </Box>
    </Box>
  )
}

function SyncDataGrid({
  appointments,
  syncHistory,
  rowSelectionModel,
  setRowSelectionModel,
  isLoading,
}: {
  appointments?: AppointmentFragment[]
  syncHistory?: SyncHistoryType[] | null
  rowSelectionModel: GridRowSelectionModel
  setRowSelectionModel: Dispatch<SetStateAction<GridRowSelectionModel>>
  isLoading?: boolean
}) {
  const { timezone } = useReduxSelector(
    (state) => state.scheduling.calendarState,
  )
  const columns: GridColDef<RowDataType>[] = useMemo(
    () => [
      {
        field: 'date',
        type: 'date',
        headerName: 'Creation Date',
        minWidth: 220,
        valueFormatter: (params: GridValueFormatterParams<Date>) =>
          formatTz(params.value, 'Pp zzz', {
            timeZone: timezone,
          }),
      },
      {
        field: 'direction',
        type: 'singleSelect',
        headerName: 'Direction',
        valueOptions: [
          { value: 'inbound', label: 'Inbound' },
          { value: 'outbound', label: 'Outbound' },
        ],
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          capitalize(params.value),
        minWidth: 100,
      },
      {
        field: 'id',
        headerName: 'Appt ID',
        minWidth: 220,
        type: 'string',
      },
      {
        field: 'apptDate',
        headerName: 'Appt Date',
        type: 'date',
        minWidth: 220,
        valueFormatter: (params: GridValueFormatterParams<Date>) =>
          formatTz(params.value, 'Pp zzz', {
            timeZone: timezone,
          }),
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'singleSelect',
        valueOptions: [
          { value: 'error', label: 'Error' },
          { value: 'success', label: 'Success' },
          // { value: 'skip', label: 'Skip' },
        ],
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          capitalize(params.value),
        minWidth: 100,
      },
      {
        field: 'message',
        headerName: 'Message',
        minWidth: 150,
        type: 'string',
        renderCell: renderHoverFullContentCell,
      },
    ],
    [timezone],
  )
  const rows = useMemo(() => {
    const unsyncAppts =
      appointments?.filter(
        (a) =>
          !a.identifiers?.some(
            (i) => i.source === AppointmentIdentifierSource.NextgenId,
          ),
      ) || []
    return unsyncAppts.map((a) => {
      const hist = getSyncHistoryByApptId(a._id, syncHistory)
      return {
        _meta: a,
        id: a._id,
        date: new Date(a.createdDate),
        direction: 'Outbound',
        apptDate: new Date(a.startDate),
        status: hist?.status || '',
        message: hist?.message || '',
      }
    })
  }, [appointments, syncHistory])
  return (
    <DataGrid
      loading={isLoading}
      columns={columns}
      rows={rows}
      autoHeight
      pagination
      initialState={{
        pagination: {
          paginationModel: { pageSize: 10, page: 0 },
        },
      }}
      checkboxSelection
      disableRowSelectionOnClick
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel)
      }}
      rowSelectionModel={rowSelectionModel}
      autosizeOptions={{
        columns: ['id', 'date', 'direction', 'apptDate'],
        includeOutliers: true,
        includeHeaders: false,
      }}
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          disableFeatures: ['quickFilter', 'densitySelector', 'exportButton'],
        },
      }}
    />
  )
}

export function SyncToEMR({
  appointments,
  syncHistory,
  isLoading,
  onClose,
}: {
  appointments?: AppointmentFragment[]
  syncHistory?: SyncHistoryType[] | null
  isLoading?: boolean
  onClose: () => void
}) {
  const notify = useNotify()
  const [state, setState] = useState<'push' | 'pull'>('push')
  const [range, setRange] = useState<DateRange<Date>>([null, null])
  const [pull, pullRes] = usePullSyncMutation({
    fixedCacheKey: 'shared-pull',
  })
  const [push, pushRes] = usePushSyncMutation({
    fixedCacheKey: 'shared-push',
  })

  // const { calendarState } = useReduxSelector((state) => state.scheduling)
  const provider = useProvider()
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([])
  const disableSync =
    (state === 'push' ? !rowSelectionModel.length : !range[0] || !range[1]) ||
    isLoading
  const onSubmit = async () => {
    if (state === 'push') {
      const res = await push({ appointmentIds: rowSelectionModel as string[] })
      if ('error' in res) {
        notify({
          message: 'Sync Failure',
          severity: 'error',
        })
      } else {
        notifyRes(res.data.pushSync?.results, notify)
      }
    }
    if (range[0] && range[1]) {
      const res = await pull({
        providerId: provider._id,
        startDate: range[0].toISOString(),
        endDate: range[1].toISOString(),
      })
      if ('error' in res) {
        notify({
          message: 'Sync Failure',
          severity: 'error',
        })
      } else {
        notifyRes(res.data.pullSync?.results, notify)
      }
    }
  }

  return (
    <Box sx={{ minWidth: '600px', height: '100%' }}>
      <Accordion onChange={() => setState('push')} expanded={state === 'push'}>
        <AccordionSummary expandIcon={<ExpandMore />} id="push">
          <Typography component="span">
            <strong>Push</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ mt: '-20px', mb: '8px' }}>
            Send these unsynced Valera appointments to NextGen:
          </Typography>
          <SyncDataGrid
            appointments={appointments}
            syncHistory={syncHistory}
            isLoading={isLoading || pullRes.isLoading || pushRes.isLoading}
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion onChange={() => setState('pull')} expanded={state === 'pull'}>
        <AccordionSummary expandIcon={<ExpandMore />} id="push">
          <Typography component="span">
            <strong>Pull</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ mt: '-20px', mb: '8px' }}>
            Retrieve NextGen appointments with a max range of 35 days:{' '}
          </Typography>
          <DateRangePicker
            value={range}
            onChange={(newValue) => setRange(newValue)}
            minDate={new Date()}
            maxDate={range[0] ? addDays(range[0], 35) : undefined}
          />
        </AccordionDetails>
      </Accordion>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          mt: '16px',
        }}
      >
        <Button
          variant="outlined"
          color="info"
          sx={{ ml: 'auto', mr: '16px' }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button onClick={onSubmit} disabled={disableSync}>
          Sync
        </Button>
      </Box>
    </Box>
  )
}
