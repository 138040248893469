import { createContext, ReactNode, useContext, useMemo } from 'react'
import { AppointmentFragment, ScheduleFragment } from '@valerahealth/rtk-query'
import { CalendarProviderType } from '../reducer'

export type ProviderCalendarContextProps = {
  // only including the properties we need in the calendar to make this more durable
  provider: CalendarProviderType
  appointments: AppointmentFragment[]
  schedules: ScheduleFragment[]
  isLoading?: boolean
  children: ReactNode | ReactNode[]
}

const Context = createContext<Omit<
  ProviderCalendarContextProps,
  'children'
> | null>(null)

Context.displayName = 'ProviderCalendarContext'

/* allows a redux like experience for all the scheduling components */

export const useProviderCalendarContext = () => useContext(Context)!
export const useProvider = () => useContext(Context)!.provider

/** provides the context necessary to share data between components for a Provider's calendar, including state, actions (already wrapped with dispatch), the provider that is being viewed, and the practiceMgrGQLApi */
export default function ProviderCalendarContext({
  provider,
  appointments,
  schedules,
  isLoading,
  children,
}: ProviderCalendarContextProps) {
  const value = useMemo(
    () => ({
      provider,
      appointments,
      schedules,
      isLoading,
    }),
    [provider, appointments, schedules, isLoading],
  )

  return <Context.Provider value={value}>{children}</Context.Provider>
}
